import { Injectable } from '@angular/core';
import { SubscriptionType } from '@app/core/interfaces/subscription-type.model';
import { PaymentTerm } from '@app/core/interfaces/payment-term.model';
import { ApplicationSubscription } from '@app/core/interfaces/subscription.model';
import { MollieSubscription } from '@app/core/interfaces/mollie-subscription.model';
import { SubscriptionTransitions } from '@app/core/interfaces/subscription-transitions.enum';
import { environment } from 'environments/environment';
import { CoreModule } from '../core.module';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: CoreModule
})
export class SubscriptionService {
  private apiLocation = environment.apiDomain + environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  public fetchSubscriptionTypes$() {
    return this.http.get<SubscriptionType[]>(this.apiLocation + '/subscription-type')
  }

  public listPaymentTerms() {
    return this.http.get<PaymentTerm[]>(this.apiLocation + '/payment-term');
  }

  public getMollieSubscription(appSubscription: ApplicationSubscription) {
    return this.http.get<MollieSubscription>(this.apiLocation + `/subscription/${appSubscription.id}/mollie-subscription/${appSubscription.mollieSubscriptionId}`);
  }

  public changePaymentTerm(currentSubscription: ApplicationSubscription, futurePaymentTermName: string) {
    return this.http.put(
      this.apiLocation + `/subscription/${currentSubscription.id}/payment-term/${futurePaymentTermName}`,
      null
    );
  }

  public cancelSubscription(subscriptionId: string) {
    return this.http.delete<void>(this.apiLocation + `/subscription/${subscriptionId}`);
  }

  public setCancellationReason(subscriptionId: string, params: { cancellationReason: string, satisfied: boolean }) {
    return this.http.post<ApplicationSubscription>(this.apiLocation + `/subscription/${subscriptionId}/cancellation-reason`, params);
  }

  public acceptDiscount(subscriptionId: string) {
    return this.http.post<ApplicationSubscription>(this.apiLocation + `/subscription/${subscriptionId}/discount`, {});
  }

  public upgradeSubscription(currentSubscription: ApplicationSubscription, subscriptionType: SubscriptionType, paymentTerm: PaymentTerm) {
    return this.http.post(
      this.apiLocation + `/subscription/${currentSubscription.id}/subscription-type`,
      {
        subscriptionType: subscriptionType,
        paymentTerm: paymentTerm
      }
    );
  }

  public determineSubscriptionTransition(
    currentSubscription: ApplicationSubscription,
    newSubscriptionType: SubscriptionType,
    newPaymentTerm: PaymentTerm
  ): SubscriptionTransitions {
    console.log(currentSubscription, newSubscriptionType, newPaymentTerm)
    let type: SubscriptionTransitions;
    if (currentSubscription.subscriptionType.name === 'free') {
      // First account creation.
      type = SubscriptionTransitions.Initial;
    } else if (currentSubscription.subscriptionType.name === newSubscriptionType.name && currentSubscription.paymentTerm.order !== newPaymentTerm.order) {
      // Payment period change.
      type = SubscriptionTransitions.PaymentTermChange;
    } else if (currentSubscription.subscriptionType.order < newSubscriptionType.order) {
      // Tier upgrade
      type = SubscriptionTransitions.TierUpgrade;
    } else if (currentSubscription.subscriptionType.order > newSubscriptionType.order) {
      // Tier downgrade 
      type = SubscriptionTransitions.TierDowngrade;
      // throw new Error('Subscription transition not implemented yet');
    }
    if (type === undefined) {
      throw new Error('Magical subscription transition found.');
    }

    return type;
  }

  public calculateMonthlySubscriptionPrice(subscriptionType: SubscriptionType, paymentTerm: PaymentTerm): number {
    return subscriptionType.pricePerMonth * paymentTerm?.discount * 1.21;
  }

  public calculateSubscriptionPrice(subscriptionType: SubscriptionType, paymentTerm: PaymentTerm): number {
    return subscriptionType.pricePerMonth * paymentTerm?.discount * paymentTerm?.months * 1.21;
  }


  public canChangePaymentTerm(subscriptions: ApplicationSubscription[]) {

    const pendingSubscriptions = subscriptions.filter(sub => sub.status === 'pending');
    // While a new subscription is pending, it is impossible to change the payment term.
    if (pendingSubscriptions.length > 0) {
      return false;
    }

    return true;
  }




}
